import { Box, Button, Card, CardContent, CardHeader, FormControl, Grid, InputAdornment, OutlinedInput, TextField } from "@mui/material"
import { ChangeEvent, useState } from "react";
import { usePayApiPayeerMutation } from "src/redux/api/cabinet";

export const FormPayeer = () => {
    const [summ, setSumm] = useState(0);
    const [PLINK, setPLINK] = useState<string>('');

    const [topUpBalanceData, { isLoading, isSuccess }] = usePayApiPayeerMutation();
    const topUpBalanceRequest = async (data: any) => {
        const jwt = localStorage.getItem('jwt');
        await topUpBalanceData({ body: data, jwt })
            .unwrap()
            .then((payload: any) => {
                setSumm(0);
                // window.open(`${payload.payLink}`, '_blank');
                // setTimeout(() => {
                //     window.location.reload();
                // }, 500);
                // setgetAlert({ type: 1, msg: payload.message });
                // setConfirmPay(false);
                setPLINK(payload.payLink);
            })
            .catch((error: any) => {

                alert("Ошибка при получении ссылки пополнения")
            })
    }

    const getPayLink = () => {
        if (summ < 1) {
            alert("Сумма пополнения от 1 рубля")
            return false
        }

        topUpBalanceRequest({ amount: summ })
    }


    const checkNumberInput = (inputText: any) => {
        const justNumbers = inputText.replace(/[^+\d]/g, "");
        const justNumbers2 = justNumbers.replace("+", "");
        return Number(justNumbers2);
    };

    return (
        <>
            <Grid container spacing={2} display='flex' justifyContent='center'>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardHeader title='Пополнить через PAYEER' />
                        <CardContent>
                            {!PLINK &&
                                <form>

                                    <FormControl fullWidth sx={{ m: 1, mx: 0 }}>
                                        <OutlinedInput
                                            placeholder='Сумма пополнения'
                                            onInput={(e: ChangeEvent<HTMLInputElement>) => {
                                                setSumm(checkNumberInput(e.target.value));
                                            }}
                                            value={summ === 0 ? '' : summ}
                                            type='string'
                                            inputProps={{
                                                inputMode: 'numeric',
                                                autoComplete: 'off',
                                            }}
                                            endAdornment={<InputAdornment position="end">₽</InputAdornment>}
                                        />
                                    </FormControl>
                                    {/* <FormControl fullWidth sx={{ m: 1, mx: 0 }}>
                                <TextField
                                    placeholder="Ваш кошелёк Payeer"
                                    helperText="Необходимо отправить деньги с данного кошелька"
                                    onInput={(e: ChangeEvent<HTMLInputElement>) => setWallet(e.target.value)}
                                />
                            </FormControl> */}
                                    <Box my={3}>
                                        <Button type="submit" disabled={isLoading} onClick={getPayLink} variant='contained' color='primary' fullWidth>
                                            {isLoading ? "Обработка..." : summ === 0 ? "Пополнить" : `Пополнить на сумму ${new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(summ))}.`}
                                        </Button>
                                    </Box>
                                </form>
                            }

                            {PLINK && <Box width='100%'>
                                <a onClick={() => setPLINK('')} style={{ border: "1px solid #061dea", textAlign: "center", fontWeight: 600, borderRadius: "6px", padding: "10px", width: "100%", color: "#061dea", display: "block" }} href={`${PLINK}`} target="_blank" rel="noreferrer" >
                                    Перейти на страницу оплаты
                                </a>
                            </Box>}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    )
}
