import { Box, Card, CardContent, CardMedia, Container, Grid, Typography } from "@mui/material"

import one from "../../../../../assets/images/landing/1.jpg";
import two from "../../../../../assets/images/landing/2.jpg";
import three from "../../../../../assets/images/landing/3.jpg";
import four from "../../../../../assets/images/landing/4.jpg";
import five from "../../../../../assets/images/landing/5.jpg";

export const OurClubs = () => {
    return (
        <>
            <Box py={6} id="our_clubs">
                <Typography variant="h4" component='h3' textAlign='center' py={3}>
                    Клубы с подпиской - доступ к закрытой части контента.
                </Typography>
                <Container maxWidth='xl'>
                    <Grid container spacing={2} display='flex' flex='wrap' justifyContent='center'>
                        <Grid item xs={6} md={2.4} display='flex' sx={{ p: 0 }}>
                            <Card>
                                <CardMedia
                                    component='img'
                                    image={one}
                                    sx={{ objectFit: "contain", maxHeight: "100%", width: "100%", p: 0 }}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={2.4} display='flex' sx={{ p: 0 }}>
                            <Card >
                                <CardMedia
                                    component='img'
                                    image={two}
                                    sx={{ objectFit: "contain", maxHeight: "100%", width: "100%", p: 0 }}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={2.4} display='flex' sx={{ p: 0 }}>
                            <Card>
                                <CardMedia
                                    component='img'
                                    image={three}
                                    sx={{ objectFit: "contain", maxHeight: "100%", width: "100%", p: 0 }}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={2.4} display='flex' sx={{ p: 0 }}>
                            <Card>
                                <CardMedia
                                    component='img'
                                    image={five}
                                    sx={{ objectFit: "contain", maxHeight: "100%", width: "100%", p: 0 }}
                                />
                            </Card>
                        </Grid>
                        <Grid item xs={6} md={2.4} display='flex' sx={{ p: 0 }}>
                            <Card>
                                <CardMedia
                                    component='img'
                                    image={four}
                                    sx={{ objectFit: "contain", maxHeight: "100%", width: "100%", p: 0 }}
                                />
                            </Card>
                        </Grid>
                    </Grid>

                    <Box py={3}>
                        <Typography mb={2} variant="h6">
                            <b>Марафон</b> - набор мини уроков и заданий, которые приводят к результату за определенный отрезок времени.
                        </Typography>
                        <Typography mb={2} variant="h6">
                            <b>Учебный курс</b> - серия учебных занятий, идущая по плану и расписанию, нацеленная на обучение одному учебному предмету, набор уроков и заданий, который приводит конкретному результату.
                        </Typography>

                        <Typography mb={2} variant="h6">
                            <b>Тариф Mini</b> - Пример формата: 3 дня , 3 записанных уроков , домашние задания, поддержка в чате. Стоимость от 600 рублей.
                        </Typography>

                        <Typography mb={2} variant="h6">
                            <b>Тариф Midi</b> - Пример формата: 5 дней , 5 записанных уроков , домашние задания, поддержка в чате. Стоимость от 5000 рублей.
                        </Typography>
                        <Typography mb={2} variant="h6">
                            <b>Тариф Maxi</b> - Пример формата: 1 встреча решение конкретного запроса. Стоимость от 25 000 рублей.
                        </Typography>
                        <Typography variant="h5">
                            Клубы с подпиской - доступ к закрытой части контента.
                        </Typography>
                    </Box>
                </Container>
            </Box>
        </>
    )
}
