const { REACT_APP_NAME } = process.env;

import { Box, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import PageContainer from "src/components/container/PageContainer";
import { useGetNotificationMutation } from "src/redux/api/cabinet";
import { Alerting } from "src/views/components_cabinet/Alerting";
// import { PageUnderDev } from "../page-under-dev/PageUnderDev"
import dayjs from "dayjs";

import {
    // MRT_Table,
    MaterialReactTable,
    useMaterialReactTable,
    type MRT_ColumnDef,
} from 'material-react-table';


export const AlertOfficePage = () => {
    const [tableData, setTableData] = useState<any[]>([]);

    const [rows, setRows] = useState<any>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);

    const [listNotify, setListNotify] = useState<any[]>([]);
    const [getAlert, setgetAlert] = useState<{ type: number, msg: string } | undefined>(undefined);

    const [userUpdateData, { isLoading, isError, isSuccess }] = useGetNotificationMutation();
    const userUpdateRequest = async () => {
        const jwt = localStorage.getItem('jwt');
        await userUpdateData({ jwt })
            .unwrap()
            .then((payload: any) => {
                // setListNotify(payload.data);
                initDataTable(payload.data);
            })
            .catch((error: any) => {
                // console.log('err userUpdateData');
                // console.log(error);

                setgetAlert({ type: 2, msg: error.data.message });
            })
    }

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        userUpdateRequest();
    }, [])


    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [

            {
                accessorFn: (row) => <Typography variant="h6" sx={{ mr: 1.5 }}>
                    {row.number}
                </Typography>,
                header: '№',
                size: 10,
            },

            {
                accessorFn: (row) => <Box>
                    <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                        {row.data.text}
                    </Typography>
                </Box>,
                header: 'Событие',
                // size: 20,
            },
            {
                accessorFn: (row) => <Box>
                    <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '16px' }}>
                        {dayjs(row.data.created_at).format('DD.MM.YYYY')}
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '14px' }}>
                        {dayjs(row.data.created_at).format('HH:mm:ss')}
                    </Typography>
                </Box>,
                header: 'Дата',
                size: 20,
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data: tableData,
        state: {
            // columnFilters,
            // globalFilter,
            isLoading,
            // pagination,
            // showAlertBanner: isError,
            // showProgressBars: isRefetching,
            // sorting,
        },
        enableStickyHeader: false,
        enableStickyFooter: false,
        muiTablePaperProps: {
            elevation: 0, //change the mui box shadow
            sx: {
                borderRadius: '0',
            },
        },
        // enableRowSelection: true, // чекбоксы для выбора
        enableGlobalFilter: false, // поиск по все таблице в шапке
        enableHiding: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,

        enableColumnFilters: false,
        enableColumnActions: false, // настройки колонки
        enableSorting: false, // отключает сортировку по колонкам
        paginationDisplayMode: 'pages',

        muiTableBodyCellProps: {
            sx: {
                borderBottom: '1px solid #e5eaef',
            },
        },
        muiTableProps: {
            sx: { boxShadow: "none" }
        },
        muiTableContainerProps: {
            sx: { maxHeight: '100%', boxShadow: "none" }
        },
        muiTableHeadCellProps: {
            sx: {
                borderBottom: '1px solid #e5eaef',
                fontSize: "1rem"
            },
        },
        muiPaginationProps: {
            rowsPerPageOptions: [10, 30, 50, 100],
            showRowsPerPage: false,
            showFirstButton: false,
            showLastButton: false,
            shape: 'rounded',
            // showRowsPerPage: false, // показать/скрыть колич на странице
            variant: 'outlined',
        },
        localization: {
            and: 'и',
            cancel: 'Отмена',
            search: 'Искать',
            showHideSearch: 'Поиск по всей таблице',
            rowsPerPage: 'Cтрок',
            goToLastPage: 'Предыдущая',
            goToNextPage: 'Следующая',
        },
        renderEmptyRowsFallback: () => (
            <Typography textAlign='center'>Ничего не найдено</Typography>
        ),

    });

    const initDataTable = (anyData: any[]) => {

        const arrDataTable: any = [];
        anyData.forEach((e, i) => {
            arrDataTable.push({
                number: i + 1,
                data: e
            })
        });
        setTableData(arrDataTable);
    }

    // useEffect(() => {
    //     if (data) 
    // }, [data])

    return (
        <>
            {/* <PageUnderDev /> */}
            {getAlert && <Alerting get={getAlert} />}

            <PageContainer title={`${REACT_APP_NAME} | Переводы`} description="Перевод средств на другой аккаунт">
                <MaterialReactTable table={table} />
            </PageContainer >

        </>
    )
}
