import { Avatar, Box, Button, Card, CardContent, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import levelPlaceholder from '../../../../assets/images/x10level.png'
import { FC } from 'react'
// import { useState } from 'react';
// import CloseIcon from '@mui/icons-material/Close';

interface IEmptyCard {
    cartWidth: string
}

export const EmptyCard: FC<IEmptyCard> = ({ cartWidth }) => {
    // const [stateModal, setStateModal] = useState<boolean>(false);
    // const [JWClone, setJWClone] = useState<boolean>(false);

    // const getModalPayClone = (type: boolean) => {
    //     setJWClone(type);
    //     setStateModal(p => !p)
    // }

    return (
        <>

            {/* new */}
            <Box mb={0} width='100%' display='flex' justifyContent='center' flexDirection='column' alignItems='center' sx={{ width: '100%' }}>
                <Box width='100%' height='100%' display='flex' justifyContent='center' flexDirection='column' alignItems='center'>
                    <Box mb={0} height='100%' display='flex' justifyContent='center' flexDirection='column' alignItems='center' sx={{ width: "97%", borderRadius: "0px", border: "3px solid transparent", background: "linear-gradient(18deg, rgba(152,111,59,1) 0%, rgba(208,171,38,0.8530871821384804) 37%, rgba(146,112,23,1) 75%, rgba(208,171,38,0.8614905434830182) 100%)" }}>

                        <Box sx={{ background: '#E1BBF8', minHeight: '214px', width: "100%", borderRadius: "0px", position: "relative" }}>
                            <Typography textAlign='center' sx={{ fontSize: "15px", fontWeight: 600, color: "#061de5", mt: 1 }}>Место пустое</Typography>

                        </Box>
                    </Box>
                </Box>

            </Box >

            {/* 

            <Dialog
                open={stateModal}
                onClose={() => setStateModal(p => !p)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <>
                    <DialogTitle id="alert-dialog-title" display='flex' justifyContent='end'>
                        <IconButton sx={{ ml: 'auto' }} onClick={() => setStateModal(p => !p)} autoFocus>
                            <CloseIcon sx={{ fontWeight: 600, fontSize: '2rem', color: '#000' }} />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>

                        <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' mt={3}>
                            <Typography variant="h3" mb={5} textAlign='center' sx={{ fontWeight: 500 }}>
                                Вы уверены что хотите приобрести {!JWClone ? "Клона" : "Ювелирного Клона"}?
                            </Typography>
                        </Box>


                        <Button color='error' size='large' fullWidth sx={{ fontSize: "20px" }}>
                            Да, я уверен!
                        </Button>
                    </DialogContent>
                    <DialogActions>
                        <Button color='error' variant='outlined' onClick={() => setStateModal(p => !p)}>
                            Отменить
                        </Button>
                    </DialogActions>

                </>


            </Dialog> */}


        </>
    )
}
