const { REACT_APP_NAME } = process.env;
import { Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Skeleton, TextField, Typography } from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PageContainer from 'src/components/container/PageContainer'
import { useGetStructureMutation, useGetsssStructureMutation } from 'src/redux/api/cabinet';
import { AppState } from 'src/store/Store';
import { Alerting } from 'src/views/components_cabinet/Alerting';
// import { TableAnaliz } from './TableAnaliz';
import { useDispatch } from 'react-redux';
import { upd_structure_history } from '../../../../redux/slices/app';
import { TableAnalizV2 } from './TableAnalizV2';
// import { useSearchParams } from 'react-router-dom';

const programs = [
    { name: 'NULL', id: 7, level: 600 },
    { name: 'MINI', id: 1, level: 1100 },
    { name: 'MIDI', id: 2, level: 5000 },
    { name: 'MAXI', id: 3, level: 25000 },
    { name: 'AUTO', id: 4, level: 300000 },
    { name: 'HOME ', id: 5, level: 3000000 },
    { name: 'SPACE ', id: 6, level: 1000 },
];

export const AnalizPageOffice = () => {
    const dispatch = useDispatch();
    const { data, structure_history } = useSelector((state: AppState) => state.app);
    const [getAlert, setgetAlert] = useState<{ type: number, msg: string } | undefined>(undefined);
    const [tarif, settarif] = useState('');
    const [closedCount, setclosed] = useState('');
    const [urovni, seturovni] = useState('7_600');
    const [loginuser, setloginuser] = useState(data.login);
    // const [urovniCheck, setUrovniCheck] = useState('');
    const [rows, setRows] = useState<any[] | undefined>(undefined);
    const [levels, setLevels] = useState<any[] | undefined>(undefined);


    const [userUpdateData, { isLoading }] = useGetStructureMutation();
    const userUpdateRequest = async (body: any) => {
        const jwt = localStorage.getItem('jwt');
        await userUpdateData({ body, jwt })
            .unwrap()
            .then((payload: any) => {
                setRows(payload.data);
                // setLevels(payload.levels);

                // setgetAlert({ type: 1, msg: payload.message });
            })
            .catch((error: any) => {
                setgetAlert({ type: 2, msg: error.data.message });
            })
    }

    const [dataGetsStructure, { isLoading: isld, isSuccess }] = useGetsssStructureMutation();
    const requestGetsStructure = async () => {
        const jwt = localStorage.getItem('jwt');
        await dataGetsStructure({ jwt })
            .unwrap()
            .then((payload: any) => {
                // setRows(payload.data);
                setLevels(payload.data);
                const find = {
                    // program: tarif,
                    closed: '',
                    level: urovni,
                    login: loginuser
                }
                userUpdateRequest(find);
                // setgetAlert({ type: 1, msg: payload.message });
            })
            .catch((error: any) => {
                setgetAlert({ type: 2, msg: error.data.message });
            })
    }


    // const changeTarif = (event: SelectChangeEvent) => {
    //     settarif(event.target.value as string);
    //     seturovni('');
    // };

    const changeСlosed = (event: string) => {
        setclosed(event);
        // seturovni('');
    };



    const changeurovni = (event: SelectChangeEvent) => {
        seturovni(event.target.value as string);
    };

    const changeLoginUser = (event: string) => {
        setloginuser(event);
    };

    const changeHistory = (u: any, t: any, l: any) => {
        dispatch(upd_structure_history({ u, t, l }));
    }

    const getNewStructure = () => {

        if (!urovni) {
            setgetAlert({ type: 2, msg: "Для поиска необходимо выбрать уровень" });

            return false
        }

        // if (!tarif) {
        //     setgetAlert({ type: 2, msg: "Для поиска необходимо выбрать тариф" });

        //     return false
        // }

        if (loginuser.length < 2) {
            setgetAlert({ type: 2, msg: "Для поиска необходимо ввести Логин" });
            return false
        }

        // добавляем в историю
        const find = {
            // program: tarif,
            closed: closedCount,
            level: urovni,
            login: loginuser
        }

        changeHistory(urovni, tarif, loginuser);
        userUpdateRequest(find);
        seturovni('');
    }

    useEffect(() => {

        // const def = {
        //     program: 7,
        //     closed: '',
        //     level: 600,
        //     login: data.login
        // }

        // userUpdateRequest(def);
        requestGetsStructure();

        if (!structure_history) {
            // settarif("7");
            seturovni("7_600");
            setloginuser(data.login);
            changeHistory("7_600", "7", data.login);
        }

    }, [])

    useEffect(() => {

        if (structure_history) {
            settarif(structure_history.t);
            seturovni(structure_history.u);
            setloginuser(structure_history.l);
        }

    }, [structure_history])

    return (
        <>
            {getAlert && <Alerting get={getAlert} />}

            <PageContainer title={`${REACT_APP_NAME} | Структура`} description="Структура">
                <Box sx={{ my: 2 }}>
                    <Box>
                        {isLoading && <>

                            <Box sx={{ display: 'flex', justifyContent: "center", alignItems: 'center', minHeight: "40vh" }}>
                                <CircularProgress />
                            </Box>
                            {/* <Skeleton
                                variant='rounded'
                                height={'20vh'}
                            /> */}
                        </>}

                        {!isLoading && !isld &&
                            <Card>
                                <CardHeader
                                    title="Структура"
                                />
                                <CardContent>
                                    <form>

                                        <Grid container spacing={1}>
                                            {/* <Grid item xs={6} md={4}>
                                        <FormControl fullWidth>
                                            <InputLabel sx={{ zIndex: 7788, fontSize: "1.2rem" }}>Тариф</InputLabel>
                                            <Select
                                                sx={{ background: '#eaeaea', "& .MuiOutlinedInput-notchedOutline": { background: "transparent" } }}
                                                value={tarif}
                                                label="Тариф"
                                                onChange={changeTarif}
                                            >
                                                {programs.map((e) => (
                                                    <MenuItem key={e.id} value={String(e.id)}>{e.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid> */}
                                            <Grid item sx={{ mb: { xs: 1, md: 0 } }} xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        value={loginuser}
                                                        onInput={(e: ChangeEvent<HTMLInputElement>) => changeLoginUser(e.target.value)}
                                                        label={<Typography sx={{ fontSize: "1.2rem" }}>
                                                            Логин
                                                        </Typography>} variant="outlined"
                                                        sx={{ fontSize: "1.2rem", "& > fieldset > legend": { fontSize: "1.2rem" } }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item sx={{ mb: { xs: 1, md: 0 } }} xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <InputLabel sx={{ zIndex: 7788, fontSize: "1.2rem" }}>Уровни</InputLabel>
                                                    <Select
                                                        sx={{ background: '#eaeaea', "& .MuiOutlinedInput-notchedOutline": { background: "transparent" } }}
                                                        value={urovni}
                                                        label="Уровни"
                                                        onChange={changeurovni}
                                                    // defaultValue={`1`}
                                                    >
                                                        {levels && levels.map((e, i) => {
                                                            return <MenuItem key={i} value={String(e.value)}>{e.title}</MenuItem>
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item sx={{ mb: { xs: 1, md: 0 } }} xs={12} md={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        type='number'
                                                        value={closedCount}
                                                        onInput={(e: ChangeEvent<HTMLInputElement>) => changeСlosed(e.target.value)}
                                                        label={<Typography sx={{ fontSize: "1.2rem" }}>
                                                            Кол-во занятых мест
                                                        </Typography>} variant="outlined"
                                                        sx={{ fontSize: "1.2rem", "& > fieldset > legend": { fontSize: "1.2rem" } }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        <Box mt={2} display='flex' sx={{ justifyContent: { xs: "center", md: "end" } }}>
                                            <Button onClick={getNewStructure}
                                                type='submit'
                                                size="large" color='success'
                                                sx={{ background: "green", color: "white", ml: 'auto', px: 5, fontSize: 18 }}
                                            >
                                                Найти
                                            </Button>
                                        </Box>
                                    </form>
                                </CardContent>

                            </Card>

                        }

                        <Box sx={{ mt: 3 }}>
                            {/* {isLoading || isld && <>
                                <Skeleton
                                    variant='rounded'
                                    height={'40vh'}
                                />
                            </>} */}

                            {rows && <TableAnalizV2 data={rows} />}
                            {/* {rows && !isLoading && !isld && isSuccess && <TableAnaliz rows={rows} />} */}

                            {!rows && !isLoading && isSuccess && <Typography textAlign='center' variant='h4' my={10}>Структура анализа не найдена</Typography>}
                        </Box>
                    </Box>
                </Box>
            </PageContainer>

        </>
    )
}
