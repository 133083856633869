import React, { FC } from 'react'

import { useEffect, useMemo, useState, ChangeEvent } from "react"
import {
    // MRT_Table,
    MaterialReactTable,
    useMaterialReactTable,
    type MRT_ColumnDef,
} from 'material-react-table';

import { useFinanceHistoryMutation } from "src/redux/api/cabinet";
import dayjs from "dayjs";
import { Box, Button, Typography } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PanoramaFishEyeRoundedIcon from '@mui/icons-material/PanoramaFishEyeRounded';


interface ITableAnalizV2 {
    data: any[]
}

export const TableAnalizV2: FC<ITableAnalizV2> = ({ data }) => {
    const [tableData, setTableData] = useState<any[]>([]);
    // MATERIAL TABLE
    const returnDot = (count: number) => {
        const arr = [];

        for (let i = 0; i < count; i++) {
            arr.push(<FiberManualRecordIcon key={i} sx={{ mr: 1, color: "#151ee9", fontSize: "26px" }} />);
        }

        return arr
    }

    const returnCircle = (count: number, closed: number) => {
        const arr = [];

        for (let i = 0; i < (count - closed); i++) {
            arr.push(<PanoramaFishEyeRoundedIcon key={i} sx={{ mr: 1, color: "#151ee9", fontSize: "24px" }} />);
        }

        return arr
    }
    // ФОРМИРОВАНИЕ СТРОК
    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: "number",
                header: '№',
                size: 10,
            },
            {
                accessorFn: (row) => <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                    {row.data.login} <Typography component="span">{row.data.clones > 0 ? `(Клон ${row.data.clones})` : "(Основной аккаунт)"}</Typography>
                </Typography>,
                header: 'Логин',
                // size: 60,
            },
            {
                accessorFn: (row) => <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                    {row.data.date_activate ? row.data.date_activate : "Неизвестно"}
                </Typography>,
                header: 'Дата активации',
                // size: 60,
            },
            {
                accessorFn: (row) => <Box>
                    <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                        {/* {row.closed_text} */}
                        {returnDot(row.data.closed)}
                        {returnCircle(row.data.count_position, row.data.closed)}
                    </Typography>
                </Box>,
                header: 'Заполнение матрицы',
                // size: 30,
            },
            {
                accessorFn: (row) => <Button
                    component='a'
                    href={row.data.link}
                    target='_blank'
                    rel='noreferer'
                    sx={{ fontWeight: 500, fontSize: '16px' }}>
                    Просмотреть

                </Button>,
                header: 'Просмотр',
                size: 20,
            },
            // {
            //     accessorFn: (row) => <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
            //         {/* {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(row.amount))} */}
            //         {row.data.amount && row.data.amount > 0.00 ? new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(row.data.amount)) : new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(0))}
            //     </Typography>,
            //     header: 'Сумма',
            //     size: 35,
            // },
            // {
            //     accessorFn: (row) => <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
            //         {row.data.balance_zho && row.data.balance_zho > 0.00 ? new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(row.data.balance_zho)) : new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(0))}
            //     </Typography>,
            //     header: 'Баланс ЖО',
            //     size: 35,
            // },
            // {
            //     accessorFn: (row) => <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
            //         {row.data.balance_null && row.data.balance_null > 0.00 ? new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(row.data.balance_null)) : new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(0))}
            //     </Typography>,
            //     header: 'Баланс NULL',
            //     size: 35,
            // },
            // {
            //     accessorFn: (row) => <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
            //         {row.data.balance && row.data.balance > 0.00 ? new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(row.data.balance)) : new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(0))}
            //     </Typography>,
            //     header: 'Баланс',
            //     size: 35,
            // },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data: tableData,
        state: {
            // columnFilters,
            // globalFilter,
            isLoading: !data ? true : false,
            // pagination,
            // showAlertBanner: isError,
            // showProgressBars: isRefetching,
            // sorting,
        },
        enableStickyHeader: false,
        enableStickyFooter: false,
        muiTablePaperProps: {
            elevation: 0, //change the mui box shadow
            sx: {
                borderRadius: '0',
            },
        },
        // enableRowSelection: true, // чекбоксы для выбора
        enableGlobalFilter: false, // поиск по все таблице в шапке
        enableHiding: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,

        enableColumnFilters: false,
        enableColumnActions: false, // настройки колонки
        enableSorting: false, // отключает сортировку по колонкам
        paginationDisplayMode: 'pages',

        muiTableBodyCellProps: {
            sx: {
                borderBottom: '1px solid #e5eaef',
            },
        },
        muiTableProps: {
            sx: { boxShadow: "none" }
        },
        muiTableContainerProps: {
            sx: { maxHeight: '100%', boxShadow: "none" }
        },
        muiTableHeadCellProps: {
            sx: {
                borderBottom: '1px solid #e5eaef',
                fontSize: "1rem"
            },
        },
        muiPaginationProps: {
            rowsPerPageOptions: [10, 30, 50, 100],
            showRowsPerPage: false,
            showFirstButton: false,
            showLastButton: false,
            shape: 'rounded',
            // showRowsPerPage: false, // показать/скрыть колич на странице
            variant: 'outlined',
        },
        localization: {
            and: 'и',
            cancel: 'Отмена',
            search: 'Искать',
            showHideSearch: 'Поиск по всей таблице',
            rowsPerPage: 'Cтрок',
            goToLastPage: 'Предыдущая',
            goToNextPage: 'Следующая',
        },
        renderEmptyRowsFallback: () => (
            <Typography textAlign='center'>Ничего не найдено</Typography>
        ),

    });

    const initDataTable = (anyData: any[]) => {

        const arrDataTable: any = [];
        anyData.forEach((e, i) => {
            arrDataTable.push({
                number: i + 1,
                data: e
            })
        });
        setTableData(arrDataTable);
    }

    useEffect(() => {
        // getRequest();
        if (data) {
            initDataTable(data)
        }
    }, [data]);
    return (
        <MaterialReactTable table={table} />

    )
}
