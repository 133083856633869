import { Avatar, Box, Container, Dialog, DialogContent, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import React from 'react'

const items = [
    { id: 1, text: "Регистрируясь на сайте https://x10-easy.ru/, пользователь обязуется ознакомиться с данными правилами. Активация личного кабинета означает согласие с правилами IТ сервиса." },
    { id: 2, text: "Каждый участник может регистрировать и активировать любое количество аккаунтов в проекте согласно правилам и количеству площадок, на которых он производит действия." },
    { id: 3, text: "Рекламировать, предоставлять информацию об услугах сайта, регистрировать новых пользователей вправе каждый пользователь, который соблюдает правила." },
    { id: 4, text: "Администрация имеет право в одностороннем порядке расширять, ограничивать или изменять качество услуг и количество информационного товара." },
    { id: 5, text: "Вы принимаете на себя ответственность периодически просматривать правила, чтобы своевременно отследить любые изменения, либо дополнения. Так же изменения в обязательном порядке дублируются в рабочих чатах в социальных сетях и в официальной группе. Администрация не несёт ответственности за допущенные вами ошибки, возникшие в случае вашей неосведомлённости об изменениях в данных правилах.    " },
    { id: 6, text: "Все финансовые операции участник осуществляет исключительно добровольно. " },
    { id: 7, text: "В клубе нет никаких финансовых возвратов." },
    { id: 8, text: "Каждый партнёр несёт ответственность за свои действия. " },
    { id: 9, text: "Участник обязан предоставлять точную, достоверную и полную информацию о себе. Сервис снимает с себя любую ответственность, если в случае предоставления не точной информации Администрация сервиса в случае возникшей необходимости не сможет вовремя связаться с участником посредством электронной почты или телефонного звонка, для разрешения возникшей ситуации." },
    { id: 10, text: "Участникам запрещено Некорректное и откровенно хамское поведение и обращение к администрации, технической поддержки, финансового отдела и участникам. Каждый подобный случай будет рассматриваться в индивидуальном порядке и при наличии неопровержимых доказательств аккаунты будут блокироваться.  " },
    { id: 11, text: "Администрация гарантирует неразглашение персональной информации об участниках, предоставленной при регистрации третьим лицам. " },
    { id: 12, text: "Администрация имеет право временно заблокировать любой кабинет за подозрительные действия, совершаемые из данного кабинета до выяснения полной картины происходящего, при выявлении мошеннических действий по отношению к IТ сервису или к другим сервиса, будут приниматься незамедлительные меры по блокировке аккаунта без возможности вывода начисленных средств.  " },
    { id: 13, text: "Любая, порочащая честь и достоинство и деловую репутацию IТ сервиса и администрации сервиса, не соответствующая действительности, выявленная представителями администрации клуба и подтвержденная фактами, будет приводить к немедленной блокировке аккаунта без возможности восстановления. " },
    { id: 14, text: "Любые спорные вопросы решаются путем обращения к администрации сервиса. " },
    { id: 15, text: "Администрация не несет ответственности за, любого рода, сбои работы, произошедшие по независящим от нас причинам. " },
    { id: 16, text: "Администрация вправе в одностороннем порядке изменять условия настоящих Правил. " },
    { id: 17, text: "Партнёр, в рамках выполнения Соглашения имеет право: совершать действия, направленные на размещение среди потенциальных клиентов информации о компании, а именно: - создания собственной команды партнёров; - вести поиск партнёров в бизнес; - распространять рекламные материалы компании среди потенциальных клиентов; - устанавливать контакты с партнёрами; - производить иные действия, не запрещённые законодательством государства, под юрисдикцией, которого находится Партнёр, и не противоречащие условиям настоящего Соглашения. " },
    { id: 18, text: "Администрация вправе заблокировать кабинет, если кабинет не активен более 3 месяца. " },
];

export const PoliticsIndex = () => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <Box minHeight="400px" mt={20}>
                <Container maxWidth="lg">
                    <Typography variant='h4'>Политика конфиденциальности</Typography>
                    <Typography variant='h6'>Ознакомьтесь с политикой конфиденциальности нашего проекта</Typography>
                    <List sx={{ mb: 3 }}>
                        {items.map((e) => (
                            <ListItem key={e.id}>
                                <ListItemIcon>
                                    {e.id}.
                                </ListItemIcon>
                                <ListItemText
                                    primary={<Typography sx={{ fontWeight: 500, fontSize: "16px" }}>
                                        {e.text}
                                    </Typography>}
                                />
                            </ListItem>
                        ))}

                        <ListItem key={19}>
                            <ListItemIcon>
                                19.
                            </ListItemIcon>
                            <ListItemText
                                primary={<Typography onClick={() => {
                                    handleClickOpen();
                                }} sx={{ fontWeight: 500, fontSize: "16px", color: "red", cursor: 'pointer' }}>
                                    Документы
                                </Typography>}
                            />
                        </ListItem>

                    </List>

                </Container>

                {/* модалка */}
                <Dialog
                    open={open}
                    onClose={handleClose}
                    fullScreen
                >
                    <IconButton onClick={handleClose} sx={{ color: "#fff", background: "red", position: 'absolute', right: "0.5rem", top: "0.5rem", width: "2.4rem", height: "2.4rem" }}>
                        X
                    </IconButton>

                    <DialogContent sx={{ touchAction: "none" }}>
                        <Container maxWidth={false} sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                            <Avatar

                                onClick={handleClickOpen}
                                sx={{ borderRadius: "4px", objectFit: 'contain', width: "40vw", height: "100%", mb: 3 }}
                                src='/doc/doc1.jpg'
                            />

                            <Avatar
                                onClick={handleClickOpen}
                                sx={{ borderRadius: "4px", objectFit: 'contain', width: "40vw", height: "100%" }}
                                src='/doc/doc2.jpg'
                            />
                        </Container>

                    </DialogContent>
                </Dialog>
            </Box>
        </>
    )
}
