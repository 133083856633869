import { Box } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { ChainItem } from './ChainItem';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppState } from 'src/store/Store';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';

interface IStructureChain {
    arrChain: any[]
}

export const StructureChain: FC<IStructureChain> = ({ arrChain }) => {
    const { data } = useSelector((state: AppState) => state.app);
    const [arrChainLocal, setArrChainLocal] = useState<any[]>([]);

    const [searchParams] = useSearchParams();
    const levelParam = searchParams.get('l');
    const programParam = searchParams.get('p');
    const mentorParam = searchParams.get('m');


    // level : 600
    // mentor_id : 7590
    // mentor_name : "Admin1(Клон 1)"
    // program : 1
    // console.log(data);

    const returnDots = () => {
        return (<>
            <MoreHorizOutlinedIcon fontSize='large' sx={{ mr: 1 }} />
        </>)
    }

    useEffect(() => {
        setArrChainLocal([]);
        let count = 0;
        const arr: any[] = [];
        for (let i = arrChain.length - 1; i >= 0; i--) {
            count++;
            if (count >= 6) {
                setArrChainLocal(arr.reverse());

                return
            } else if (arrChain.length == count) {
                setArrChainLocal(arr.reverse());
            }
            arr.push(arrChain[i]);
        }
    }, [arrChain]);


    return (
        <>
            <Box
                py={.5}
                sx={{ width: "100%" }}
                display='flex'
                flexWrap='wrap'
                alignItems='center'
            >
                <Box display='flex' alignItems='center' mb={1}>
                    <ChainItem active={false} item={{ level: levelParam, mentor_name: data.login, mentor_id: 0, program: programParam }} />
                </Box>
                {arrChain.length > 6 ?
                    <Box display='flex' alignItems='center' mb={1}>
                        {returnDots()}
                    </Box> :
                    <Box display='flex' alignItems='center' mb={1}>
                        <ArrowForwardIcon sx={{ mr: 1 }} />
                    </Box>
                }

                {arrChainLocal.length > 0 && arrChainLocal.map((e: any, i: number) => {
                    if (e.user_id === data.id) return

                    return <Box key={i} display='flex' alignItems='center' mb={1}>
                        <ChainItem active={Number(mentorParam) === Number(e.mentor_id) ? true : false} item={{ level: e.level, mentor_name: e.mentor_name, mentor_id: e.mentor_id, program: e.program }} />
                        {i !== arrChainLocal.length - 1 && <ArrowForwardIcon sx={{ mr: 1 }} />}
                    </Box>
                }

                )}
            </Box>
        </>
    )
}
