import { Avatar, Box, Button, Card, CardContent, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material'
import levelPlaceholder from '../../../../assets/images/x10level.png'
import { FC, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { usePayMarketingMutation } from 'src/redux/api/cabinet';
// import { useNavigate } from 'react-router';
import { Alerting } from 'src/views/components_cabinet/Alerting';
import { BronButton } from './BronButton';

interface Idata {
    data: any
    params: any
    cartWidth: string
}

export const EmptyCardPayClone: FC<Idata> = ({ data, params, cartWidth }) => {
    const [stateModal, setStateModal] = useState<boolean>(false);
    const [JWClone, setJWClone] = useState<boolean>(false);
    const [techClone, settechClone] = useState<number>(0);
    const [getAlert, setgetAlert] = useState<{ type: number, msg: string } | undefined>(undefined);

    const getModalPayClone = (type: boolean) => {
        setJWClone(type);
        setStateModal(p => !p)
    }


    const [reqData, { isLoading, isError }] = usePayMarketingMutation();
    const Request = async (body: any) => {
        const jwt = localStorage.getItem('jwt');
        await reqData({ body, jwt })
            .unwrap()
            .then((payload: any) => {
                setStateModal(p => !p)
                window.location.reload()

            })
            .catch((error: any) => {

                // setLevels(error.data.levels);
                // setErrtext(error.data.message);
                setgetAlert({ type: 2, msg: error.data.message });
                // setNeedPaymantBtn(!error.paymant);
            })
    }

    const payClone = (body: any) => {
        Request(body)
    }


    return (
        <>



            {/* new */}
            <Box mb={0} display='flex' justifyContent='center' flexDirection='column' alignItems='center' sx={{ width: '100%' }}>
                <Box width='100%' height='100%' display='flex' justifyContent='center' flexDirection='column' alignItems='center'>
                    <Box mb={2} height='100%' display='flex' justifyContent='center' flexDirection='column' alignItems='center' sx={{ width: "97%", borderRadius: "0px", border: "3px solid transparent", background: "linear-gradient(18deg, rgba(152,111,59,1) 0%, rgba(208,171,38,0.8530871821384804) 37%, rgba(146,112,23,1) 75%, rgba(208,171,38,0.8614905434830182) 100%)" }}>

                        {typeof data.visible !== 'undefined' &&
                            !data.visible ?
                            <></>
                            :
                            <Box sx={{ background: '#E1BBF8', minHeight: '214px', width: "100%", borderRadius: "0px", position: "relative" }}>
                                <Box sx={{ background: '#E1BBF8', p: 0, pb: 0 }}>
                                    <Typography textAlign='center' sx={{ fontSize: "15px", fontWeight: 600, color: "#061de5", mt: 1 }}>Место пустое</Typography>
                                    <Box display='flex' mt={3} mb={2} justifyContent='center'>
                                        <Box mt={0} display='flex' justifyContent='center' flexDirection='column' alignItems='center' px={.3}>
                                            {data.pay &&
                                                <>
                                                    <Button sx={{ mb: 1, py: .2, px: .8 }} size='small' onClick={() => {
                                                        getModalPayClone(false)
                                                        settechClone(0);
                                                    }}>
                                                        Купить клона
                                                    </Button>
                                                    <Button size='small' sx={{ background: "#1700ca", color: "#fff", mb: 1, py: .2, px: .8 }} onClick={() => {
                                                        getModalPayClone(false);
                                                        settechClone(1);
                                                    }}>
                                                        Купить ТК
                                                    </Button>
                                                </>
                                            }

                                            {data.pay_jewelry &&
                                                <Button sx={{ mb: 1, p: 0 }} size='small' onClick={() => {
                                                    getModalPayClone(true)
                                                    settechClone(0);
                                                }
                                                }>
                                                    Купить ЮК
                                                </Button>}

                                            {data.bron &&
                                                <BronButton bronData={data} />
                                            }

                                        </Box>

                                    </Box>

                                    {/* <Box display='flex' justifyContent='center' mb={1} >
                            <Chip sx={{ background: "transparent", borderRadius: '6px' }}
                                label={""}
                            />
                        </Box> */}
                                </Box>
                            </Box>
                        }
                        {/* количество клонов */}
                        {/* <Box display='none' justifyContent='center' mb={.4}>
                                <Chip sx={{ height: "25px !important", p: 0, background: '#041ee9', color: "#fff", borderRadius: '6px', fontSize: "14px" }}
                                    label={data.count ? data.count : "0"}
                                />

                                {data.clones > 0 &&
                                    <Chip sx={{ height: "25px !important", p: 0, background: data.clones > 0 ? data.teh ? "#05d9db" : '#68EB9C' : "transparent", borderRadius: '6px', ml: 0.5 }}
                                        label={data.clones > 0 ? data.teh ? `ТК ${data.clones}` : `Клон ${data.clones}` : ""}
                                    />
                                }

                            </Box> */}

                    </Box>
                </Box>

            </Box >

            {/* {"visible" in data && data.visible === true || !data.visible &&  */}
            {typeof data.visible !== 'undefined' &&
                !data.visible ?
                <></>
                :
                <Dialog
                    open={stateModal}
                    onClose={() => setStateModal(p => !p)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <>
                        {getAlert && <Alerting get={getAlert} />}
                        <DialogTitle id="alert-dialog-title" display='flex' justifyContent='end'>
                            <IconButton sx={{ ml: 'auto' }} onClick={() => setStateModal(p => !p)} autoFocus>
                                <CloseIcon sx={{ fontWeight: 600, fontSize: '2rem', color: '#000' }} />
                            </IconButton>
                        </DialogTitle>
                        <DialogContent>

                            <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column' mt={3}>
                                <Typography variant="h3" mb={5} textAlign='center' sx={{ fontWeight: 500 }}>
                                    Вы уверены что хотите поставить {!JWClone ? techClone === 1 ? "Тех. клона" : "Клона" : "Ювелирного Клона"}?
                                </Typography>
                            </Box>
                            <Button color='error' size='large' fullWidth sx={{ fontSize: "20px" }} onClick={() => {
                                const body: any = { ...params };
                                body.mentor_id = Number(data.mentor_id);

                                if (JWClone) body.jeverly = true;
                                else body.no_jeverly = true

                                body.teh = techClone;

                                // console.log(body);
                                payClone(body);
                            }}>
                                Да, я уверен!
                            </Button>
                        </DialogContent>
                        <DialogActions>
                            <Button color='error' variant='outlined' onClick={() => setStateModal(p => !p)}>
                                Отменить
                            </Button>
                        </DialogActions>
                    </>

                </Dialog>}

        </>
    )
}
