import { FC, useEffect } from 'react';
import { Box, CssBaseline, ThemeProvider, Typography } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'src/store/Store';
import { ThemeSettings } from './theme/Theme';
import RTL from './layouts/full/shared/customizer/RTL';
import ScrollToTop from './components/shared/ScrollToTop';
import Router from './routes/Router';
import { AppState } from './store/Store';
import { useDispatch } from 'react-redux';
import { setDarkMode } from './store/customizer/CustomizerSlice';
import { iLogin, updLoad } from './redux/slices/app';
import { useCheckAuthMutation } from './redux/api/auth';

const App: FC = () => {
  const routing = useRoutes(Router);
  const theme = ThemeSettings();
  const customizer = useSelector((state: AppState) => state.customizer);
  // const app = useSelector((state: AppState) => state.app);
  const dispatch = useDispatch();
  const modeLight = localStorage.getItem('mode');
  const jwt = localStorage.getItem("jwt");

  const [checkData] = useCheckAuthMutation();
  const registerAnswer = async () => {
    await checkData({ jwt })
      .unwrap()
      .then((payload: any) => {
        dispatch(iLogin(payload.data))
        setTimeout(() => {
          dispatch(updLoad(false))
        }, 500);
      })
      .catch(() => {
        dispatch(updLoad(false))
        // localStorage.removeItem('jwt');
      })
  }

  useEffect(() => {
    // работа с темой при перезагрузке
    if (modeLight) dispatch(setDarkMode(modeLight));
    else dispatch(setDarkMode("light"));
    // проверка наличия jwt
    if (jwt) {
      registerAnswer();
    } else dispatch(updLoad(false));
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <RTL direction={customizer.activeDir}>
        <CssBaseline />
        <ScrollToTop>{routing}</ScrollToTop>
      </RTL>
    </ThemeProvider>
  );
};

export default App;
