const { REACT_APP_NAME } = process.env;

import { useEffect, useMemo, useState, ChangeEvent } from "react"
import {
    // MRT_Table,
    MaterialReactTable,
    useMaterialReactTable,
    type MRT_ColumnDef,
} from 'material-react-table';

import { useFinanceHistoryMutation } from "src/redux/api/cabinet";
import dayjs from "dayjs";
import { Typography } from "@mui/material";

export const BalanceHistory = () => {
    const [data, setData] = useState<any[]>([]);


    const [getData, { isLoading, isSuccess, isError }] = useFinanceHistoryMutation();
    const getRequest = async () => {
        const jwt = localStorage.getItem('jwt');
        await getData({ jwt })
            .unwrap()
            .then((payload: any) => {
                initDataTable(payload.data);
                // setAnswerData(payload.data);
                // dispatch(updProfileData(payload.data));
                // notify('Данные успешно изменены');
            })
            .catch((error: any) => {
                // console.log('err getPartnersData');
                // console.log(error);
                // notifyErr(error.data.message);
                // setAlert(true);
                // setAlertMsg(error.data.message);
                // setAlertStatus('success');
            })
    }

    // MATERIAL TABLE

    // ФОРМИРОВАНИЕ СТРОК
    const columns = useMemo<MRT_ColumnDef<any>[]>(
        () => [
            {
                accessorKey: "number",
                header: '№',
                size: 10,
            },
            {
                accessorFn: (row) => <Typography sx={{ fontWeight: 500 }}>
                    {row.data.type_id}
                </Typography>,
                header: 'Тип',
                size: 10,
            },
            {
                accessorFn: (row) => <Typography textAlign='center' sx={{ fontWeight: 500 }}>
                    {dayjs(row.data.created_at).format('DD.MM.YYYY HH:mm')}
                </Typography>,
                header: 'Дата',
                size: 10,
            },
            {
                accessorFn: (row) => <Typography textAlign='center' sx={{ fontWeight: 500, fontSize: '16px', color: row.data.status_id.color }}>
                    {row.data.status_id.name}
                </Typography>,
                header: 'Статус',
                size: 10,
            },
            {
                accessorFn: (row) => <Typography sx={{ fontWeight: 500, fontSize: '15px' }}>
                    {row.data.name}
                </Typography>,
                header: 'Описание',
                // size: 200,
            },
            {
                accessorFn: (row) => <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                    {/* {new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(row.amount))} */}
                    {row.data.amount && row.data.amount > 0.00 ? new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(row.data.amount)) : new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(0))}
                </Typography>,
                header: 'Сумма',
                size: 10,
            },
            {
                accessorFn: (row) => <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                    {row.data.balance_zho && row.data.balance_zho > 0.00 ? new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(row.data.balance_zho)) : new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(0))}
                </Typography>,
                header: 'Баланс ЖО',
                size: 10,
            },
            {
                accessorFn: (row) => <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                    {row.data.balance_null && row.data.balance_null > 0.00 ? new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(row.data.balance_null)) : new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(0))}
                </Typography>,
                header: 'Баланс NULL',
                size: 10,
            },
            {
                accessorFn: (row) => <Typography sx={{ fontWeight: 500, fontSize: '16px' }}>
                    {row.data.balance && row.data.balance > 0.00 ? new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(row.data.balance)) : new Intl.NumberFormat("ru", { style: "currency", currency: "RUB", minimumFractionDigits: 0 }).format(Number(0))}
                </Typography>,
                header: 'Баланс',
                size: 10,
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data,
        state: {
            // columnFilters,
            // globalFilter,
            isLoading,
            // pagination,
            // showAlertBanner: isError,
            // showProgressBars: isRefetching,
            // sorting,
        },
        enableStickyHeader: false,
        enableStickyFooter: false,
        muiTablePaperProps: {
            elevation: 0, //change the mui box shadow
            sx: {
                borderRadius: '0',
            },
        },
        // enableRowSelection: true, // чекбоксы для выбора
        enableGlobalFilter: false, // поиск по все таблице в шапке
        enableHiding: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,

        enableColumnFilters: false,
        enableColumnActions: false, // настройки колонки
        enableSorting: false, // отключает сортировку по колонкам
        paginationDisplayMode: 'pages',

        muiTableBodyCellProps: {
            sx: {
                borderBottom: '1px solid #e5eaef',
            },
        },
        muiTableProps: {
            sx: { boxShadow: "none" }
        },
        muiTableContainerProps: {
            sx: { maxHeight: '100%', boxShadow: "none" }
        },
        muiTableHeadCellProps: {
            sx: {
                borderBottom: '1px solid #e5eaef',
                fontSize: "1rem"
            },
        },
        muiPaginationProps: {
            rowsPerPageOptions: [10, 30, 50, 100],
            showRowsPerPage: false,
            showFirstButton: false,
            showLastButton: false,
            shape: 'rounded',
            // showRowsPerPage: false, // показать/скрыть колич на странице
            variant: 'outlined',
        },
        localization: {
            and: 'и',
            cancel: 'Отмена',
            search: 'Искать',
            showHideSearch: 'Поиск по всей таблице',
            rowsPerPage: 'Cтрок',
            goToLastPage: 'Предыдущая',
            goToNextPage: 'Следующая',
        },
        renderEmptyRowsFallback: () => (
            <Typography textAlign='center'>Ничего не найдено</Typography>
        ),

    });

    const initDataTable = (anyData: any[]) => {

        const arrDataTable: any = [];
        anyData.forEach((e, i) => {
            arrDataTable.push({
                number: i + 1,
                data: e
            })
        });
        setData(arrDataTable);
    }

    useEffect(() => {
        getRequest();
    }, []);

    return (
        <MaterialReactTable table={table} />
    )
}
