import { Box, Button, Checkbox, Drawer, Fab, FormControl, FormControlLabel, FormGroup, FormLabel, IconButton, MenuItem, Select, SelectChangeEvent, Theme, Grid, Typography, useMediaQuery } from "@mui/material";
import TuneIcon from '@mui/icons-material/Tune';
import { useState } from "react";
// import { StructureChain } from "./StructureChain";
import { SearchInStructure } from "./SearchInStructure";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { TextTimer } from "./TextTimer";
import { ReturnM } from "./ReturnM";

export const RightDrawer = () => {

    // const [drawState, setDrawState] = useState(false);
    // const payAbonPlata = () => payAboPlataRequest();

    // const payCloneForPloshadka = (request: any) => {
    //     // console.log(request);
    //     Request(request);
    // }

    return (
        <>

        </>
    )
}
