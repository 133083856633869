
import { FC, useEffect, useState } from "react"
import { Avatar, Box, Button, Card, CardContent, Chip, CircularProgress, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import levelPlaceholder from '../../../../assets/images/x10level.png'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { Link, useSearchParams } from "react-router-dom";
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Palette } from "@mui/icons-material";
import ContentPasteSearchRoundedIcon from '@mui/icons-material/ContentPasteSearchRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { usePostBodyWithJwtMutation } from "src/redux/api/cabinet";
import { Alerting } from "src/views/components_cabinet/Alerting";

interface IUserCard {
    data: any
    place: string
    updateModalState: () => void
    updateDataModal: (data: any) => void
    cartWidth: string // было 155px на обычной
    localCountChip?: boolean | number
}

export const UserCard: FC<IUserCard> = ({ data, place, updateModalState, updateDataModal, cartWidth, localCountChip }) => {
    // const [counter, setCounter] = useState<undefined | number>(undefined);
    // const [getAlert, setgetAlert] = useState<{ type: number, msg: string } | undefined>(undefined);
    const [searchParams] = useSearchParams();
    const programParam = searchParams.get('p');
    const levelParam = searchParams.get('l');

    // const [reqDataGetCounters, { isLoading, isSuccess: isSuccGetCounters }] = usePostBodyWithJwtMutation();
    // const RequestGetCounters = async (body: any) => {
    //     const jwt = localStorage.getItem('jwt');
    //     const route = 'views-counter-one';
    //     await reqDataGetCounters({ body, jwt, route })
    //         .unwrap()
    //         .then((payload: any) => {
    //             setCounter(Number(payload.data));
    //             // setgetAlert({ type: 1, msg: payload.message });
    //         })
    //         .catch((error: any) => {
    //             setgetAlert({ type: 2, msg: error.data.message });
    //         })
    // }

    const openModal = (data: any) => {
        updateModalState();
        updateDataModal(data);
    }

    // const getCounter = (mentor_id: number) => {
    //     const data = {
    //         mentor_id,
    //         program: programParam,
    //         level: levelParam,
    //     }
    //     RequestGetCounters(data);
    // }

    // useEffect(() => {
    //     console.log(data);
    // }, []);

    return (
        <>
            {/* {getAlert && <Box sx={{ zIndex: 7777 }}>
                <Alerting get={getAlert} />
            </Box>} */}
            {/* new */}
            <Box mb={0} minHeight='100%' display='flex' justifyContent='center' flexDirection='column' alignItems='center' sx={{ width: '100%' }}>
                <Box width='100%' height='100%' display='flex' justifyContent='center' flexDirection='column' alignItems='center'>
                    <Box mb={2} display='flex' justifyContent='center' flexDirection='column' alignItems='center' sx={{ width: "97%", borderRadius: "0px", border: "3px solid transparent", background: "linear-gradient(18deg, rgba(152,111,59,1) 0%, rgba(208,171,38,0.8530871821384804) 37%, rgba(146,112,23,1) 75%, rgba(208,171,38,0.8614905434830182) 100%)" }}>

                        <Box sx={{ background: '#E1BBF8', minHeight: '214px', width: "100%", borderRadius: "0px", position: "relative" }}>
                            {/* header карточки кнопки */}

                            <Box display='flex' justifyContent='center' my={.4}>
                                <Chip
                                    sx={{ height: "22px !important", p: 0, background: '#041ee9', color: "#fff", borderRadius: '6px', fontSize: "14px", "& > span": { px: '6px !important' } }}
                                    // label={data.count ? data.count : "0"}
                                    label={data.counter}
                                />

                                {data.clones > 0 &&
                                    <Chip sx={{ height: "22px !important", p: 0, background: data.clones > 0 ? data.teh ? "#05d9db" : '#68EB9C' : "transparent", borderRadius: '6px', ml: 0.5, "& > span": { px: '4px !important' } }}
                                        label={data.clones > 0 ? data.teh ? `ТК ${data.clones}` : `Клон ${data.clones}` : ""}
                                    />
                                }

                            </Box>
                            <Box mt={1}>
                                <Typography textAlign='center' sx={{ fontSize: cartWidth === '155px' ? "15px" : '13px', fontWeight: 600, color: "#061de5" }}>{data && data.date}</Typography>
                            </Box>

                            {/* тело */}
                            <Box display='flex' justifyContent='center' flexDirection='column' alignItems='center' mb={.5}>
                                <Avatar
                                    sx={{
                                        width: '50px', height: "50px", objectFit: "contain", mb: .5
                                    }}
                                    src={data.avatar ? data.avatar : levelPlaceholder}
                                />
                                <Typography textAlign='center' sx={{
                                    fontSize: "18px", fontWeight: 500, color: "red", textDecoration: "none", width: "128px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}>{data && data.login}</Typography>
                            </Box>

                            {/* данные куратора */}
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", background: "transparent", p: .5, width: "100%" }}>
                                {/* <Avatar
                                    variant='circular'
                                    src={data && data.avatar_referer}
                                    sx={{
                                        width: '40px', height: "40px", p: .5, mr: .5, "& img": {
                                            borderRadius: "100px"
                                        }
                                    }}
                                /> */}
                                <Box>
                                    {/* <Typography textAlign='center' sx={{ fontSize: "20px", fontWeight: 600, color: "#fff" }}>Куратор:</Typography> */}
                                    {/* <Typography textAlign='center' sx={{ fontSize: "16px", fontWeight: 500, color: "#061de5", textDecoration: "underline" }}>
                                        ({data && data.login_referer})
                                    </Typography> */}
                                    <Typography textAlign='center' sx={{
                                        fontSize: "16px", fontWeight: 500, color: "#061de5", textDecoration: "none", width: "128px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }}>({data && data.login_referer})</Typography>
                                </Box>
                            </Box>

                            {/* стрелки */}
                            <Grid container spacing={0} display='flex' alignItems='center' justifyContent='start' sx={{ mt: 1, pt: 0 }}>
                                <Grid item xs={4}>
                                    <IconButton sx={{ p: 0.5 }} aria-label="Информация о пользователе"
                                        onClick={() => openModal(data)}
                                    >
                                        {/* <InfoOutlinedIcon sx={{ color: "#fff", fontSize: "1.7rem" }} /> */}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="22"
                                            height="26"
                                            fill="none"
                                            viewBox="0 0 8 9"
                                        >
                                            <g clipPath="url(#clip0_72_4940)">
                                                <path
                                                    fill="#151ee9"
                                                    d="M5.166 7.254l-.1.412c-.304.12-.545.21-.725.273-.18.063-.39.094-.629.094-.366 0-.652-.09-.855-.268a.868.868 0 01-.305-.681c0-.107.007-.216.023-.328.015-.112.04-.238.074-.379l.378-1.339c.034-.128.062-.25.085-.364.023-.115.035-.22.035-.314 0-.171-.036-.29-.106-.358-.07-.067-.205-.101-.405-.101a1.06 1.06 0 00-.3.046 4.2 4.2 0 00-.266.087l.102-.413c.247-.101.484-.187.711-.26.227-.071.44-.107.643-.107.364 0 .645.088.842.264a.876.876 0 01.296.685c0 .059-.006.161-.02.308a2.056 2.056 0 01-.076.404l-.377 1.334a3.544 3.544 0 00-.12.679c0 .177.04.298.12.362.08.064.216.096.412.096.091 0 .195-.016.311-.048.116-.032.2-.06.252-.084zm.096-5.6a.78.78 0 01-.264.594.897.897 0 01-.634.245.907.907 0 01-.638-.245.779.779 0 01-.266-.594c0-.232.088-.431.266-.596A.903.903 0 014.364.81c.247 0 .459.083.634.248a.788.788 0 01.264.596z"
                                                ></path>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_72_4940">
                                                    <path
                                                        fill="#fff"
                                                        d="M0 0H7.223V7.223H0z"
                                                        transform="translate(.054 .81)"
                                                    ></path>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </IconButton>
                                </Grid>
                                <Grid item xs={4} display='flex' alignItems='center' justifyContent='center'>

                                    {data.up > 0 &&
                                        <IconButton component={Link} to={`?p=${data.program}&l=${data.level}&m=${data.up}`} sx={{ width: "10px", height: "10px", p: 0 }}>
                                            <KeyboardArrowUpRoundedIcon sx={{ fontSize: "40px", color: "#061de5" }} />
                                        </IconButton>
                                    }

                                    {data.down > 0 &&
                                        <IconButton component={Link} to={`?p=${data.program}&l=${data.level}&m=${data.down}`} sx={{ width: "10px", height: "10px", p: 0 }}>
                                            <KeyboardArrowDownRoundedIcon sx={{ fontSize: "40px", color: "#061de5" }} />
                                        </IconButton>
                                    }

                                </Grid>
                                <Grid item xs={4} display='flex' alignItems='center' justifyContent='space-around'>
                                    {data.asc && <Box >
                                        <IconButton
                                            component={Link}
                                            to={`?p=${data.asc.program}&l=${data.asc.level}&m=${data.asc.mentor_id}`}
                                            aria-label="Смотреть куда встанет"
                                            sx={{ p: 0 }}
                                        >
                                            <ContentPasteSearchRoundedIcon sx={{ fontSize: "1.6rem", color: "#061de5" }} />
                                        </IconButton>
                                    </Box>}
                                </Grid>
                            </Grid>
                            {/* количества клонов */}
                            {/* <Box display='flex' justifyContent='center' mb={.4}>
                                <Chip sx={{ height: "25px !important", p: 0, background: '#041ee9', color: "#fff", borderRadius: '6px', fontSize: "14px" }}
                                    label={data.count ? data.count : "0"}
                                />

                                {data.clones > 0 &&
                                    <Chip sx={{ height: "25px !important", p: 0, background: data.clones > 0 ? data.teh ? "#05d9db" : '#68EB9C' : "transparent", borderRadius: '6px', ml: 0.5 }}
                                        label={data.clones > 0 ? data.teh ? `ТК ${data.clones}` : `Клон ${data.clones}` : ""}
                                    />
                                }

                                
                            </Box> */}
                        </Box>
                    </Box>
                </Box>

            </Box >

        </>
    )
}
